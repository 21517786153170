import { cx } from 'lib/cx'
import Image from 'next/image'
import file from 'public/scribbles/tolingo.svg'

export default function TolingoBrand(props: { className?: string }) {
  return (
    <div
      className={cx(
        props.className,
        'h-[1.5em] -my-[.25em] w-[4.5em] relative',
      )}
    >
      <Image src={file} fill alt="tolingo" />
    </div>
  )
}
